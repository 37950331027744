.dialog-content {
	max-height: 80vh;
	overflow-y: auto;
	padding: 16px;
}

.dialog-actions {
	display: flex;
	justify-content: center;
	padding: 8px;
	border-top: 1px solid #e0e0e0;

	& > * {
		margin: 0 10px;
	}
}

.custom-dialog-container {
	background-color: white;
	width: 100%;
	max-width: 600px;
	padding: 16px;
	border-radius: 4px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.custom-dialog-container .dialog-content {
	margin: 0;
	padding: 0;
}
