@import '@fontsource-variable/recursive/full.css';
@import '@fontsource-variable/material-symbols-rounded';
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './dialog-styles.scss';

.material-icons {
	font-family: 'Material Symbols Rounded Variable';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

.cdk-drag-preview {
	@apply bg-gray-500 bg-opacity-50;
}

.cdk-drag-placeholder {
	@apply bg-gray-200;
}

.cdk-drop-list-dragging {
	@apply bg-gray-100;
}

.track-matches-result-list {
	@apply bg-yellow-400;
}

.track-matches-section {
	@apply bg-green-400;
}

.track-is-placeholder {
	@apply bg-gray-100;
}

.track-out-of-scoring-range {
	color: dimgray;
	background-color: #ffffff;
	opacity: 0.5;
	background-size: 9px 9px;
	background-image: repeating-linear-gradient(45deg, #f74545 0, #f74545 0.9px, #ffffff 0, #ffffff 50%);
}

.form-field-error-message {
	@apply text-xs text-red-500 text-left mt-2 pl-3;
}

.form-field-warn-message {
	@apply text-xs text-orange-500 text-left mt-2 pl-3;
}
